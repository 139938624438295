import React, { Fragment, useEffect, useId, useState } from "react";
import {
    Route,
    BrowserRouter as Router,
    Routes
} from "react-router-dom";
import DefaultLayout from "./components/Layout/DefaultLayout";
import { privateRoutes, publicRoutes } from "./routes";

import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebError from "./pages/WebError";
import ProtectedRoute from "./routes/ProtectedRoute";
function ErrorFallback({ error, resetErrorBoundary }) {
    return <WebError />;
}

var DEBUG = true;
if (!DEBUG) {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info", "error"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {};
    }
}

function App() {
    const [currentDomain, setCurrentDomain] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [urlImg, setURLImg] = useState("");

    useEffect(() => {
        setCurrentDomain(window.location.href);
 
        
            setTitle("IoT - Humatic");
            setURLImg("/image/humatic.png");
        
    }, []);
    const id = useId();

    return (
        <Router>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Nền tảng giám sát IoT" />
                <meta property="og:title" content={title} />
                <meta
                    property="og:description"
                    content="Nền tảng giám sát IoT"
                />
                <meta property="og:image" content={urlImg} />
                <link rel="icon" href={urlImg} sizes="32x32" />
                <link rel="icon" href={urlImg} sizes="192x192" />
                {/*Thêm các thẻ meta khác tại đây*/}
            </Helmet>
            <ToastContainer />
            <div className="App">
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            {privateRoutes.map((route, index) => {
                                const Page = route.component;

                                let Layout;
                                if (route.layout === null) {
                                    Layout = Fragment;
                                } else if (route.layout) {
                                    Layout = route.layout;
                                } else {
                                    Layout = DefaultLayout;
                                }

                                return (
                                    <Route
                                        key={id}
                                        path={route.path}
                                        element={
                                            <Layout>
                                                <Page />
                                            </Layout>
                                        }
                                    />
                                );
                            })}
                        </Route>
                        {publicRoutes.map((route) => {
                            const Page = route.component;

                            let Layout;
                            if (route.layout === null) {
                                Layout = Fragment;
                            } else if (route.layout) {
                                Layout = route.layout;
                            } else {
                                Layout = DefaultLayout;
                            }

                            return (
                                <Route
                                    key={id}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}
                    </Routes>
                </ErrorBoundary>
            </div>
        </Router>
    );
}

export default App;
